body {
    margin: 3% 6%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.callout {
    padding: 30px 0 20px 0;
    border-radius: 10px;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 700px;
    height: 400px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.logo-img {
    width: 100px;
}

.logo-img:hover {
    cursor: pointer;
}

.header span {
    margin-right: 20px;
}
